import {getImage} from './assembleImage';
import transformate1 from './../images/portfolio/transformate/transformate1.jpg';
import transformate2 from './../images/portfolio/transformate/transformate2.jpg';
import transformate3 from './../images/portfolio/transformate/transformate3.jpg';
import transformate4 from './../images/portfolio/transformate/transformate4.jpg';
import transformate5 from './../images/portfolio/transformate/transformate5.jpg';
import transformate6 from './../images/portfolio/transformate/transformate6.jpg';
import transformate7 from './../images/portfolio/transformate/transformate7.jpg';


export const transformateImages = [
        getImage(transformate1),
        getImage(transformate2),
        getImage(transformate3),
        getImage(transformate4),
        getImage(transformate5),
        getImage(transformate6),
        getImage(transformate7)
    ]