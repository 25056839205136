import React from "react";
import "./index.css";
import instagram from "./../../images/instagram.svg";
import behance from "./../../images/behance.svg";
import linkedIn from "./../../images/linkedin.svg";
import youtube from "./../../images/youtube.svg";
import facebook from "./../../images/facebook.svg";
import tiktok from "./../../images/tiktok.svg";
import dribbble from "./../../images/dribbble.svg";
import artstation from "./../../images/artstation.svg";
import goUp from "./../../images/goUp.png";


const FooterComponent = (props) => {
  
  return (
    <div style={{ position: "relative" }}>
      <div className="footerContainer">
      <div className="footerComponent">
        <div className="contactComp">
          <label className="contacUs2">{"Contact Us"}</label>
          <div className="contactComponent">
          <label className="comercial">{"Comercial@comicplay.studio"}</label>
          <label className="numberCell">{"+57 301 372 7779"}</label>
          </div>
        </div>

        <div className="networkComp">
          <span className="followUs">Follow Us!</span> 

          <div className="socialNetworkLiks">
            <div>
              <a href="https://www.instagram.com/comicplay.studio/">
                <img src={instagram} className="snIcon" alt="instagram" />
              </a>
              <a href="https://www.facebook.com/comicplaystudio">
                <img src={facebook} className="snIcon" alt="facebook" />
              </a>
              <a href="https://www.tiktok.com/@comicplaystudio?lang=es">
                <img src={tiktok} className="snIcon" alt="tiktok" />
              </a>
              <a href="https://www.behance.net/comicplaystudio">
                <img src={behance} className="snIcon" alt="behance" />
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="goUpContainer">
      <img src={goUp} className="upIcon" alt="upIcon"  onClick={() => props.scroll("home")}/> 

      </div>
    </div>
  );
};

export default FooterComponent;
