import React from 'react';
import '../CarouselComponent/index.css'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { config } from "react-spring";
import Carousel from "react-spring-3d-carousel";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CarouselComponent(props) {

    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (e) => {
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        if (e.currentTarget.id === "imagesDialog") {
            setOpen(false);
        }
    };

    const handleGoToSlide = (index) => {
        setState({ goToSlide: index });
    };

    const slides = props.images.map((slide, index) => {
        return { ...slide, onClick: () => handleGoToSlide(index) };
    });

    return (
        <div style={{ flex: "1 1 0px" }}>
            <img src={props.images[0].url} alt={props.name} onClick={handleClickOpen} style={{ width: "100%" }} />
            <Dialog id="imagesDialog" fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} onClick={handleClose}>
                <div className="closeDialogContainer">
                    <span className="closeButton">X</span>
                </div>
                <div style={{display: "flex", flexDirection:"column", textAlign:"center", alignItems:"center"}}>
                    <div id="carouselDialog" className="carousel" onClick={handleClose}>
                        <Carousel 
                            width="100%"
                            slides={slides}
                            goToSlide={state.goToSlide}
                            offsetRadius={2}
                            showNavigation={true}
                            animationConfig={config.slow}
                            style={{ opacity: "1" }}
                        />
                        <div
                            style={{
                                margin: "0 auto",
                                marginTop: "2rem",
                                width: "50%",
                                display: "flex",
                                justifyContent: "space-around"
                            }}>
                        </div>
                    </div>
                    <div style={{alignItems: "center",display: "flex",flexDirection: "column"}}>
                        <p className="CarouselLabel">{props.name}</p>
                        <p className="CarouselLabel2">{props.year}</p>
                        <p className="CarouselText">{props.details}</p>
                    </div>
                </div>
            </Dialog>

        </div>
    );
}


