import React, { createRef, useEffect } from 'react';
import './App.css';
import HomePage from './components/HomePage';
import QuoteHerePage from './components/QuoteHerePage';
import TermsPage from './components/TermsPage';
import { Helmet } from 'react-helmet';



const App = () => {
  const [page, setPage] = React.useState('HOME');
  const canonicalUrl = window.location.href;

  const refMap = new Map();
  refMap.set('home', createRef());
  refMap.set('ourTeam', createRef());
  refMap.set('services', createRef());
  refMap.set('portfolio', createRef());
  refMap.set('contact', createRef());
  refMap.set('footer', createRef());
  const mainParallaxRef = createRef();

  useEffect(() => {
    console.log(page.htmlElRefLabel);
    const htmlElRef = refMap.get(page.htmlElRefLabel)
    if (htmlElRef && htmlElRef.current) {
      mainParallaxRef.current.scrollTo(0, 0);
      let offset = htmlElRef.current.offsetTop / (scrollCorrection(page.htmlElRefLabel));
      console.log(offset);
      if (page.htmlElRef === refMap.get('footer')) {
        var rect = page.htmlElRef.current.getBoundingClientRect();
        if (window.innerHeight - rect.top - rect.height > 0) {
          mainParallaxRef.current.scrollTo(0, offset);
          return false;
        }
      } else {
        mainParallaxRef.current.scrollTo(0, offset)
      }
    }
  })

  const scrollCorrection = (type) => {
    let speed;
    if (type === 'ourTeam') {
      speed = (window.innerWidth < 900 && window.innerWidth > 450) ? 2.35 :
      (window.innerWidth < 450) ? 2.45 : 2.40;
    } else {
      if (type === 'services') {
        speed = (window.innerWidth < 900 && window.innerWidth > 450) ? 1.00 :
          (window.innerWidth < 450) ? 0.72 : 0.58;
      } else {
        if (type === 'portfolio') {
          speed = (window.innerWidth < 900 && window.innerWidth > 450) ? 1.47 :
            (window.innerWidth < 450) ? 1.19 : 1.1;

        } else {
          if (type === 'contact') {
            speed = (window.innerWidth < 900 && window.innerWidth > 450) ? 1.56 :
              (window.innerWidth < 450) ? 1.51 : 1.40;
          } else {
            if (type === 'footer') {
              speed = (window.innerWidth < 900 && window.innerWidth > 450) ? 1.98 :
                (window.innerWidth < 450) ? 2.42 : 1.88;
            }
          }

        }
      }
    }
    return speed;
  }



  const scroll = (ref) => {
    if (page.page !== 'HOME' || !page.htmlElRefLabel !== ref)
      setPage({ page: 'HOME', htmlElRefLabel: ref });
  }

  return (
    <div className="App">
       <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className={(!page.page || page.page === 'HOME') ? null : 'displayNone'}>
        <HomePage scroll={scroll}
          home={refMap.get('home')}
          ourTeam={refMap.get('ourTeam')}
          services={refMap.get('services')}
          portfolio={refMap.get('portfolio')}
          contact={refMap.get('contact')}
          footerRef={refMap.get('footer')}
          mainParallaxRef={mainParallaxRef}
          setPage={setPage} />
      </div>
      <div className={(page.page === 'QUOTES') ? null : 'displayNone'}>
        <QuoteHerePage scroll={scroll} />
      </div>
      <div className={(page.page === 'TERMS') ? null : 'displayNone'}>
        <TermsPage scroll={scroll} />
      </div>


    </div>
  )
}

export default App