import {getImage} from './assembleImage';
import tecnoglass1 from './../images/portfolio/tecnoglass/tecnoglass1.jpg';
import tecnoglass2 from './../images/portfolio/tecnoglass/tecnoglass2.jpg';
import tecnoglass3 from './../images/portfolio/tecnoglass/tecnoglass3.jpg';
import tecnoglass4 from './../images/portfolio/tecnoglass/tecnoglass4.jpg';
import tecnoglass5 from './../images/portfolio/tecnoglass/tecnoglass5.jpg';
import tecnoglass6 from './../images/portfolio/tecnoglass/tecnoglass6.jpg';
import tecnoglass7 from './../images/portfolio/tecnoglass/tecnoglass7.jpg';


export const tecnoglassImages = [
        getImage(tecnoglass1),
        getImage(tecnoglass2),
        getImage(tecnoglass3),
        getImage(tecnoglass4),
        getImage(tecnoglass5),
        getImage(tecnoglass6),
        getImage(tecnoglass7)
    ]