import {getImage} from './assembleImage';
import logofolio1 from './../images/portfolio/logofolio/diseno-logo-bogota.jpg';
import logofolio2 from './../images/portfolio/logofolio/diseno-logo-barranquilla.jpg';
import logofolio3 from './../images/portfolio/logofolio/logofolio3.jpg';
import logofolio4 from './../images/portfolio/logofolio/logofolio4.jpg';
import logofolio5 from './../images/portfolio/logofolio/logofolio5.jpg';
import logofolio6 from './../images/portfolio/logofolio/logofolio6.jpg';
import logofolio7 from './../images/portfolio/logofolio/logofolio7.jpg';


export const logofolioImages = [
        getImage(logofolio1, "diseño logo bogota"),
        getImage(logofolio2, "diseño logo barranquilla"),
        getImage(logofolio3),
        getImage(logofolio4),
        getImage(logofolio5),
        getImage(logofolio6),
        getImage(logofolio7)
    ]