import React from 'react';
import './index.css'
import VisibilitySensor from "react-visibility-sensor";


const InfoGraphicComponent = (props) => {
    return(
        <div ref={props.ref} onFocus={props.onFocus} className={props.className}>
            <div className={props.iconClassName}>
                <img src={props.logoUrl} alt={props.name}/>
            </div>
            <span  ref={props.reference} className={props.nameClassName+" nameLabel"}> {props.name} </span>
            <i className ={props.bulletClassName}> </i>
            
            <label className={props.componentFooterClassName}>{props.componentFooter} </label>
            <VisibilitySensor>
                {({ isVisible }) =>(
                    <div className={props.descriptionClassName + (isVisible || !props.fadeInActive ? " fadeIn enter" : " fadeIn")}>
                        <span className={props.infoGraphicSeparatorTop}/>
                        <span className={" descriptionLabel"}>{props.description}</span>
                        <div className={props.infoGraphicSeparatorBottom}/>
                    </div>
                )}
            </VisibilitySensor>
            
        </div>
    )
}


export default InfoGraphicComponent