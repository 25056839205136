import React from 'react';
import './index.css'
import ToolBarMenu from './../ToolbarMenu';
import FooterComponent from '../FooterComponent';
import whiteLayer from './../../images/white-layerII.png';
import productBox from './../../images/Product-Box.png';
import goUp from './../../images/goUp.png';

const QuoteHerePage = (props) => {
  return (
    <div className="App" style={{backgroundColor: "#e26934"}}>
      <ToolBarMenu scroll={props.scroll} />
      <div className="QuoteHere">
        <span className="QuotesLabel">
          <p>.BE COOL</p>
          <p style={{marginLeft:'1.7vw', marginBottom: '2vw'}}>LET’S WORK TOGETHER</p>
          <a href="#home"><img src={goUp} className="invertedIcon" alt="goDown" /></a>
        </span>
        <img src={productBox} style={{width: "38vw", height: "36.5vw"}} alt="productBox"/>
      </div>
      <div>
        <div style={{position: "absolute"}}>
          <div className="QuotesContent">
            <span className="QuotesTitle">
              <p>OUR PROCESS</p>
            </span>
            <span className="QuotesSubTitle">
            <ol className="QuoteList">
              <li>SCHEDULE A CALL
                <p style={{width:"30vw", fontSize:"1.2vw", fontFamily:"gravity-regular", marginBottom:"5vw", textAlign:"justify"}}>Tell us what you need, we want to get to know you and help you with your projects. Schedule a 
                <span style={{ cursor: "pointer", color:"#e26934" }} onClick={()=>props.scroll('contact')}> virtual appointment </span>
                or message us on
                <a style={{color:"#e26934"}} href={"https://api.whatsapp.com/send?phone=573013727779&text=Hola%2c%20quiero%20conocer%20sus%20servicios%21&source=&data=&app_absent="}> WhatsApp </a>.
                </p>
              </li>
              <li>PITCH YOUR PROJECT TO US
              <p style={{width:"30vw", fontSize:"1.2vw", fontFamily:"gravity-regular", marginBottom:"5vw", textAlign:"justify"}}>We'll discover what's best for your project or brand through a simple questionnaire we'll complete together. This way, we'll be ready to craft the best proposal for you.</p>
              </li>
              <li>LET'S GET DESIGNING!
              <p style={{width:"30vw", fontSize:"1.2vw", fontFamily:"gravity-regular", textAlign:"justify"}}>Finally, with all the information gathered, we'll take care of designing the best proposals for your needs and schedule a delivery date.</p>
              </li>
            </ol>
            </span>
          </div>
        </div>
        <img src={whiteLayer} style={{display: "block"}} className="white-layer" alt="" />
      </div>
      <div style={{backgroundColor:"white"}}>
        <FooterComponent scroll={props.scroll}/>
      </div>
    </div>
  )
}

export default QuoteHerePage