import React from "react";
import "./index.css";
import rayas from "./../../../images/rayas.png";
import cruz from "./../../../images/cruz.png";
import flechas from "./../../../images/flechas.png";
import fondo2 from "./../../../images/fondo2.png";
import raya2 from "./../../../images/raya2.png";

const AboutUs = () => (
  <div div className="AboutUsContainer">
    <img src={fondo2} className="fondo2" alt="fondo2" />
    <img src={cruz} className="cruz" alt="cruz" />
    <img src={flechas} className="flechas" alt="flechas" />
    <img src={rayas} className="rayas" alt="rayas" />
    <img src={raya2} className="raya2" alt="raya2" />
    <h1 className="usTitle"> {"ABOUT US"}</h1>
    <h1 className="AboutUstitle"> {"WHO ARE WE?"} </h1>
 
    <div className="AboutUsComp">
      <p className="AboutUsLabel">
        {
          "Based in Colombia, we are a creative studio specializing in graphic design and illustration, with a strong focus on web design, motion graphics, and branding. We collaborate with companies and brands to create memorable digital projects and products."
        }
      </p>
    </div>
  </div>
);

export default AboutUs;
