import React from 'react';
import logo from './../../images/branding-barranquilla.png';
import whatsappIcon from './../../images/whatsapp.png';

const ToolBarMenu = (props) => {
    return (
        <div className="toolbar">
           
            <img id="home" src={logo} className="App-logo" alt="branding barranquilla" onClick={()=>props.scroll('home')}/>
          

            <ul className="HeaderMenu">
                <li>
                    <div style={{ cursor: "pointer" }} onClick={()=>props.scroll('home')}>Home</div>
                </li>
                <li>
                    <div style={{ cursor: "pointer" }} onClick={()=>props.scroll('ourTeam')}>About us</div>
                </li>
                <li>
                    <div style={{ cursor: "pointer" }} onClick={()=>props.scroll('services')}>Services</div>
                </li>
                <li>
                    <div style={{ cursor: "pointer" }} onClick={()=>props.scroll('portfolio')}>Portfolio</div>
                </li>
                <li>
                    <div style={{ cursor: "pointer" }} onClick={()=>props.scroll('contact')}>Contact us</div>
                </li>
                <li>
                    <a href={"https://api.whatsapp.com/send?phone=573013727779&text=Hola%2c%20quiero%20conocer%20sus%20servicios%21&source=&data=&app_absent="}>
                        <img src={whatsappIcon} className="wsp-logo" alt="wspLogo" />
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default ToolBarMenu