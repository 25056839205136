import React from "react";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import MeetOurTeamComponent from "./MeetOurTeamComponent";
import ServicesComponent from "./ServicesComponent";
import PortfolioComponent from "./PortfolioComponent";
import AboutUs from "./AboutUs";
import standsOut from "./../../images/stands-out.png";
import standsOutMobile from "./../../images/stands-out-mobile.png";
import buildings from "./../../images/Street.png";
import characters from "./../../images/spaceman.gif";
import sky from "./../../images/DiaComic.png";
import whiteLayer from "./../../images/white-layer.png";
import ToolBarMenu from "./../ToolbarMenu";
import FooterComponent from "../FooterComponent";
import TestimonyCarousel from "../HomePage/TestimonyCarousel";
import ToolBarMenuMobile from '../ToolbarMenuMobile';
import whatsappIcon from './../../images/whatsapp.png';

const HomePage = (props) => {
  const goToQuotes = () => props.setPage({ page: "QUOTES" });
  const nowDate = new Date();
  const day = new Date();
  day.setHours(5);
  day.setMinutes(0);
  const afternoon = new Date();
  afternoon.setHours(12);
  afternoon.setMinutes(0);
  const night = new Date();
  night.setHours(18);
  night.setMinutes(0);

  return (
    <div className="App">
      <div className="whatsapp-link">
        <a href={"https://api.whatsapp.com/send?phone=573013727779&text=Hola%2c%20quiero%20conocer%20sus%20servicios%21&source=&data=&app_absent="}>
            <img src={whatsappIcon} className="wsp-logo" alt="wspLogo" />
        </a>
      </div>
      <Parallax className="mainParallaxContainer" pages={1}>
        <div ref={props.home} />
        <div className="HeaderMenuWeb">
        <ToolBarMenu scroll={props.scroll} />

        </div>
        <div className="HeaderMenuResponsive">
        <ToolBarMenuMobile scroll={props.scroll} />

        </div>
        <div
          ref={props.mainParallaxRef}
          style={{
            height: "100%",
            position: "relative",
            overflow: "hidden scroll",
          }}
        >
          <ParallaxLayer offset={0} speed={0.6}>
            <img src={characters} className="characters" alt="characters" />
            <img alt="cube" className="CubeGif" />
            <img alt="characters" className="Personajes" />

            <img src={buildings} className="buildings" alt="branding bogota" />
            <ParallaxLayer offset={0} speed={1}>
              <div className="headerTitleContainer">
                <p className="headerTitle1">{"BOOST YOUR BRAND'S VIBE WITH"}</p>
                <p className="headerTitle2">{"KILLER DESIGN, ILLUSTRATION"}</p>
                <p className="headerTitle2">{"& MULTIMEDIA"}</p>
              </div>
              <img src={whiteLayer} className="white-layer" alt="" />
              <AboutUs />
              <div ref={props.ourTeam} />
              <MeetOurTeamComponent />
              <ParallaxLayer offset={0} speed={0.4}>
                <div style={{ position: "relative" }}>
                  <div className="standsOutContainer">
                    <p className="standsOutTitle">{".Stand out"}</p>
                    <p className="standsOutBody">
                      {
                        "Wanting to make a splash in the market is totally cool, and we totally get it. That's why we're all about offering graphic solutions that are 'outside the box' for you and our community. If that sounds like your vibe, we're here to help you create a standout brand with serious personality!"
                      }
                    </p>
                    <div className="standsOutButton" onClick={goToQuotes}>
                      <p className="standsOutButtonLabel">{"GET A QUOTE"}</p>
                      <p className="standsOutButtonLabelBold">&nbsp;{"TODAY"}</p>
                      <span className="arrow-right" />
                    </div>
                  </div>
                  <img
                    src={standsOut}
                    className="stands-out"
                    alt="stands-out"
                  />
                  <img
                    src={standsOutMobile}
                    className="stands-out-mobile"
                    alt="stands-out"
                  />
                </div>
                <div ref={props.services} />
                <ServicesComponent />
                <div ref={props.portfolio} />
                <PortfolioComponent />
                <TestimonyCarousel/>

                <div ref={props.contact} />

                <FooterComponent scroll={props.scroll} />
                <div ref={props.footerRef} />
              </ParallaxLayer>
            </ParallaxLayer>
          </ParallaxLayer>
          {day < nowDate && afternoon > nowDate ? (
            <img src={sky} className="sky" alt="sky" />
          ) : afternoon < nowDate &&  night > nowDate ? (
            <img alt="" className="afternoon" />
          ) : (
            <img alt="" className="night" />
          )}
        </div>
      </Parallax>
    </div>
  );
};

export default HomePage;
