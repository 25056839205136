import "./index.css";
import React from "react";

const Testimonials = (props) => {
  return (
    <div className={(props.currentIndex===props.index)?'ContainerTestimonials':'ContainerTestimonialsOff'}>
      {console.log('currentIndex:'+props.currentIndex)}
      {console.log('index:'+props.index)}
      <img alt="" className={(props.currentIndex===props.index)?'quotationMarks':'quotationMarksOff'}/>
      <div className="DescriptionTestimonial">{props.description}</div>
      <div className="SergioChams">{props.SergioText}</div>
      <div className="InfoClient">
      <img alt="" className="ImgClient" src={props.image}/>
      <div className="TitleTesti">{props.title}</div>
      <div className="ClientCompany">{props.company}</div>
      </div>
    </div>
  );
};

export default Testimonials;