import {getImage} from './assembleImage';
import eisMillano1 from './../images/portfolio/eis-milano/logo.jpg';
import eisMillano2 from './../images/portfolio/eis-milano/menu.jpg';
import eisMillano3 from './../images/portfolio/eis-milano/pagina-web.jpg';
import eisMillano4 from './../images/portfolio/eis-milano/diseno.jpg';
import eisMillano5 from './../images/portfolio/eis-milano/branding.jpg';
import eisMillano6 from './../images/portfolio/eis-milano/logo2.jpg';

export const eisMilanoImages = [
    getImage(eisMillano1),
    getImage(eisMillano2),
    getImage(eisMillano3),
    getImage(eisMillano4),
    getImage(eisMillano5),
    getImage(eisMillano6)
]
