import {getImage} from './assembleImage';
import illustration1 from './../images/portfolio/illustration/casa-tragaluz.jpg';
import illustration2 from './../images/portfolio/illustration/higiene-market.jpg';
import illustration3 from './../images/portfolio/illustration/ilustracion-digital.jpg';
import illustration4 from './../images/portfolio/illustration/delirio-mesa-de-trabajo.jpg';
import illustration5 from './../images/portfolio/illustration/delirio-bajo-el-tacto.jpg';
import illustration6 from './../images/portfolio/illustration/post-dia-del-disenador.jpg';
import illustration7 from './../images/portfolio/illustration/tragaluz.jpg';
import illustration8 from './../images/portfolio/illustration/post-dia-de-la-madre.jpg';
import illustration9 from './../images/portfolio/illustration/nave.jpg';
import illustration10 from './../images/portfolio/illustration/anime.jpg';
import illustration11 from './../images/portfolio/illustration/personaje.jpg';


export const illustrationImages = [
        getImage(illustration1),
        getImage(illustration2),
        getImage(illustration3),
        getImage(illustration4),
        getImage(illustration5),
        getImage(illustration6),
        getImage(illustration7),
        getImage(illustration8),
        getImage(illustration9),
        getImage(illustration10),
        getImage(illustration11)
    ]