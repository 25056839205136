import logo from './../../images/branding-barranquilla.png';
import whatsappIcon from './../../images/whatsapp.png';
import React, { useState } from "react";
import "./index.css";
const ToolBarMenuMobile = (props) => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    return (
        <div className="toolbarMobile">
             <div className="burgerComponent">
             <img id="home" src={logo} className="logoComic" alt="branding barranquilla" onClick={()=>props.scroll('home')}/>

             <img alt='menu' className="ImgBurger" onClick={handleClick} />

             </div>
           
          
             <div className={click ? "BurgerMenuComp active": "BurgerMenuComp"}>
             <img alt='close' className="ImgCloseMenu" onClick={closeMobileMenu}/>

            <ul className="HeaderMenuMobile" >
                <li onClick={closeMobileMenu}>
                    <div className="ItemsMobile" style={{ cursor: "pointer" }} onClick={()=>props.scroll('home')}>Home</div>
                </li>
                <li onClick={closeMobileMenu}>
                    <div  className="ItemsMobile"style={{ cursor: "pointer" }} onClick={()=>props.scroll('ourTeam')}>About us</div>
                </li>
                <li onClick={closeMobileMenu}>  
                    <div className="ItemsMobile"style={{ cursor: "pointer" }} onClick={()=>props.scroll('services')}>Services</div>
                </li>
                <li onClick={closeMobileMenu}>
                    <div  className="ItemsMobile"style={{ cursor: "pointer" }} onClick={()=>props.scroll('portfolio')} >Portfolio</div>
                </li>
                <li onClick={closeMobileMenu}>
                    <div  className="ItemsMobile" tyle={{ cursor: "pointer" }} onClick={()=>props.scroll('contact')}>Contact us</div>
                </li>
                <li onClick={closeMobileMenu}>
                    <a href={"https://api.whatsapp.com/send?phone=573013727779&text=Hola%2c%20quiero%20conocer%20sus%20servicios%21&source=&data=&app_absent="}>
                        <img src={whatsappIcon} className="wsplogo" alt="wspLogo" />
                    </a>
                </li>
            </ul>
        </div>
        </div>
    )
}

export default ToolBarMenuMobile;