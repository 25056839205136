import {getImage} from './assembleImage';
import gatortech1 from './../images/portfolio/gatortech/logo.jpg';
import gatortech2 from './../images/portfolio/gatortech/logo2.jpg';
import gatortech3 from './../images/portfolio/gatortech/tarjeta-de-presentacion.jpg';
import gatortech4 from './../images/portfolio/gatortech/banner.jpg';
import gatortech5 from './../images/portfolio/gatortech/anuncio.jpg';
import gatortech6 from './../images/portfolio/gatortech/responsive.jpg';


export const gatortechImages = [
        getImage(gatortech1),
        getImage(gatortech2),
        getImage(gatortech3),
        getImage(gatortech4),
        getImage(gatortech5),
        getImage(gatortech6)
    ]