import React from 'react';
import './index.css'
import ToolBarMenu from '../ToolbarMenu';
import FooterComponent from '../FooterComponent';
import cross from './../../images/cross.png';
import circle1 from './../../images/circle.png';
import circle2 from './../../images/circle.png';
import black1 from './../../images/black1.png';
import black2 from './../../images/black2.png';
import line1 from './../../images/rayas.png';
import line2 from './../../images/rayas.png';
import line3 from './../../images/rayas.png';
import line4 from './../../images/rayas.png';
import goUp from './../../images/goUp.png';
import goDown from './../../images/goDown.png';
import x1 from './../../images/x.png';
import x2 from './../../images/x.png';
import orange1 from './../../images/orange.png';
import orange2 from './../../images/orange.png';
import ButtonUp from "../TermsPage/ButtonUp";


const TermsPage = (props) => {
 
  return (
    <div className="App" style={{backgroundColor: "#white" }}>
      <ToolBarMenu scroll={props.scroll} />
     <div>
   
      </div>
      <img src={cross} className="cross1" alt="cross1"/>
      <img src={cross} className="cross2" alt="cross2"/>
      <img src={cross} className="cross3" alt="cross3"/>
      <img src={circle1} className="circle1" alt="circle1"/>
      <img src={circle2} className="circle2" alt="circle2"/>
      <img src={black1} className="black1" alt="black1"/>
      <img src={black2} className="black2" alt="black2"/>
      <img src={line1} className="line1" alt="line1"/>
      <img src={line2} className="line2" alt="line2"/>
      <img src={line3} className="line3" alt="line3"/>
      <img src={line4} className="line4" alt="line4"/>
      <img src={goUp} className="goUp" alt="goUp"/>
      <img src={goDown} className="goDown" alt="goDown"/>
      <img src={x1} className="x1" alt="x1"/>
      <img src={x2} className="x2" alt="x2"/>
      <img src={orange1} className="orange1" alt="orange1"/>
      <img src={orange2} className="orange2" alt="orange2"/>
 <div className="termsComp">
              <p className="termsTitle" >{".NUESTROS"} </p>
              <p className="termsTitle2" >{"TÉRMINOS Y CONDICIONES"} </p>
              <p className="line" >{"____________________________________________________"} </p>
              <p className="subtitle" >{"TÉRMINOS Y CONDICIONES COMICPLAY STUDIO S.A.S."} </p>
              <p className="subtitle" >{"TÉRMINOS Y CONDICIONES DE USO DE LA PÁGINA WEB DE COMICPLAY STUDIO S.A.S."} </p>
              <p className="subtitle" >{"POLÍTICAS"} </p>
              <p className="paragraph" >{"El sitio web www.comicplay.studio, es controlado y operado por ComicPlay Studio S.A.S., ubicado en la ciudad de Barranquilla, Colombia; el cual pone a disposición los servicios de desarrollo de contenido digital, producción audiovisual y diseño gráfico a quien lo utiliza, el USUARIO. Esta sociedad se constituyó como microempresa, identificada con Nit: 901.369.172-1, documento privado del 15 de enero del 2020, inscrita en la Cámara de Comercio el 21 de febrero del 2020, bajo el número 378.022 del libro IX. "} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. ofrece al mercado, servicios de Branding Corporativo, Diseño Web, Multimedia e Ilustración y Servicios adicionales. Estos servicios incluyen un precio fijo y son definidos, de acuerdo al tamaño del proyecto. "} </p>
              <p className="paragraph" >{"Quien accede al sitio web se le atribuye la condición de usuario y acepta de esta manera, las condiciones generales de los servicios creativos. Así mismo, el usuario acepta, en el momento del acceso al sitio web, las condiciones particulares o adicionales, que le complementen, modifiquen o sustituyan."} </p>
              <p className="paragraph" >{"En este orden de ideas, el usuario consiente haber leído y entendido las condiciones del sitio web, lo que implica acogerse y dar cumplimiento a las políticas, términos y condiciones del sitio web, como las normas y leyes aplicables a los servicios de desarrollo creativo en Colombia."} </p>
              <p className="paragraph" >{"La aceptación del sitio web implica estar sujetos a los cambios, a las condiciones de uso, política de privacidad, tratamiento y protección de datos personales. Así mismo, implica para el usuario, usar el sitio debidamente con buena fe en el contenido o declaraciones que realice e identidad del usuario."} </p>
              <p className="subtitle" >{"DEFINICIONES"} </p>
              <p className="paragraph" >{"Usuario: Es la persona natural o jurídica, de cualquier naturaleza, que ingresa a la página web y acepta los términos y condiciones de ComicPlay Studio S.A.S, para conocer el contenido, solicitar información o para recibir un servicio creativo. "} </p>
              <p className="paragraph" >{"Creadores de los servicios creativos: Se refiere al recurso humano encargado de diseñar, crear y desarrollar los diferentes servicios ofrecidos por ComicPlay Studio S.A.S, incluyen ilustradores digitales, diseñadores gráficos y desarrolladores de software. "} </p>
              <p className="paragraph" >{"Propuesta económica: Es un documento que describe una oferta de trabajo y económica a realizar, elaborado con el fin de solicitar la aprobación técnica y financiera del usuario,  para su ejecución. "} </p>
              <p className="paragraph" >{"Servicios creativos: Conjunto de diseños digitales, creados o desarrollados, de acuerdo a los requerimientos establecidos y pactados entre el cliente y ComicPlay Studio S.A.S. "} </p>
              <p className="paragraph" >{"Versión creativa: Es la propuesta, boceto o borrador, que se muestra al cliente para tener una visualización aproximada de cómo quedará el proyecto finalizado. "} </p>
              <p className="paragraph" >{"Avances: Son las entregas que recibirá el cliente, como seguimiento del desarrollo del proceso, de un proyecto que se está ejecutando."} </p>
              <p className="subtitle" >{"PROPIEDAD INDUSTRIAL Y DERECHOS DE AUTOR"} </p>
              <p className="paragraph" >{"El usuario reconoce con el acceso al sitio web a favor de ComicPlay Studio S.A.S., la propiedad exclusiva sobre los derechos de propiedad industrial, en las nuevas creaciones o signos distintivos en el desarrollo o producción de servicio creativo contratado. "} </p>
              <p className="paragraph" >{"En ese orden de ideas, el usuario acepta que ComicPlay Studio S.A.S. es titular de la obra creada, producida y desarrollada en relación al diseño, contenido audiovisual; publicitario; gráfico; publicitario; multimedia; fotográfico;  artes, contenidos (en forma digital e impreso), textos y base de datos."} </p>
              <p className="paragraph" >{"De acuerdo a lo anterior, el usuario entenderá que no tiene propiedad sobre lo entregado (físico o digital), no existe cesión de derechos patrimoniales, no tiene derechos sobre la titularidad de la obra, no tendrá disposición de la obra, no exhibición pública, no autorización de transformación; no modificación, no distribución y no reproducción de la misma. "} </p>
              <p className="paragraph" >{"El derecho del usuario sobre el diseño, producción, desarrollo del servicio creativo se limita al uso. En ese sentido, la disposición del servicio creativo por el usuario se limita al objeto del contrato, en que se fijará la autorización o el fin del servicio creativo. El resultado del servicio creativo será intransferible a menos que el usuario lo compre por un contrato de compraventa.  "} </p>
              <p className="subtitle" >{"RESPONSABILIDAD"} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. no asume responsabilidad del contenido del sitio web; la interrupción, la inexactitud, los errores de carácter técnico, la imprecisión, fallas en el funcionamiento  y tampoco habrá responsabilidad por alteraciones o ausencia de virus en el sitio web. "} </p>
              <p className="paragraph" >{"La responsabilidad de ComicPlay Studio S.A.S. comprende satisfacer las necesidades del usuario con respecto al objeto los servicios ofrecidos y dar cumplimiento con la entrega del proyecto dentro de los 30 días siguientes al pago del abono. No obstante; estos términos serán suspendidos por circunstancias adversas o ajenas que impidan la actividad, por ejemplo: solicitudes del usuario, reuniones ocasionales, aprobación del borrador, informaciones pendientes o respuestas pendientes y sugerencias del cliente al proyecto. "} </p>
              <p className="paragraph" >{"El usuario acuerda eximir de cualquier responsabilidad a ComicPlay Studio S.A.S.  y en contra de terceros, con respecto a los servicios creativos, la información suministrada, materiales indicados, cualquier autorización o licencias que se requiera,  diseño o contenido físico o digital.  "} </p>
              <p className="subtitle" >{"CONDICIONES DE LA COMPRA "} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. iniciará el servicio creativo con el abono del 50% del precio establecido en la propuesta económica o cotización. El abono indicará la aceptación del cliente con respecto a la propuesta económica y la aprobación de los términos y condiciones en el perfeccionamiento del contrato. De acuerdo al criterio de la directora del área comercial, ComicPlay Studio S.A.S. podrá iniciar el trabajo con un abono distinto al señalado en la propuesta económica. "} </p>
              <p className="paragraph" >{"El cliente se compromete a realizar el pago restante del precio establecido en la propuesta económica con la entrega del proyecto. Los pagos son necesarios para el desarrollo del proyecto, sí el cliente no paga implicará retrasos en la terminación del proyecto. ComicPlay Studio S.A.S. suspenderá o terminará los servicios creativos cuando el cliente no cancele."} </p>
              <p className="subtitle" >{"PLANES DE SUSCRIPCIÓN"} </p>
              <p className="paragraph" >{"El servicio de community manager y contenido para redes sociales, consta de un plan de suscripción para brindarle opciones al cliente y que este escoja el que mejor le parezca."} </p>
              <p className="subtitle" >{"PRECIO Y PRODUCTO"} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. señala que los precios incluyen IVA y tiene una vigencia de treinta (30) días a partir de la fecha del envío de la propuesta económica. Los precios establecidos en la propuesta económica son estimados por las necesidades del usuario. Así que la propuesta económica estimará lo que necesite el usuario al momento del contacto. No obstante, la propuesta económica será indicativa al cumplimiento del usuario de los requerimientos de ComicPlay Studio S.A.S., plazos establecidos o la aprobación del proyecto."} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. indica que el precio incluye generalmente tres (3) versiones creativas por proyecto. En el caso que el cliente no quede satisfecho, ComicPlay Studio S.A.S. desarrollara la segunda entrega de la versión creativa. Si a la tercera entrega de la versión creativa el cliente sigue insatisfecho, ComicPlay Studio S.A.S. podrá dar por terminado el contrato con el cliente, sin devolución de los depósitos o anticipos realizados. Según el tipo de proyecto, existen aproximadamente entre dos a tres entregas ya sean: pre-diseño, boceto o borrador antes de elaborar el proyecto. "} </p>
              <p className="paragraph" >{"Las versiones creativas y entregas de los avances es la búsqueda de la satisfacción del cliente con respecto al proyecto, estos variarán de acuerdo al proyecto. En el caso del diseño de logo y mascota tres (3) versiones creativas y tres (3) entregas, del material corporativo y brochure una (1) versión creativa y dos (2) entregas. En el caso del membrete para powerpoint es una (1) versión y una (1) entrega. En el caso del diseño web es una (1) versión y dos (2) entregas. En el caso de multimedia e ilustración es una (1) versión y dos (2) entregas. Por tanto, cada entrega de avance es el resultado de los ajustes y modificaciones al proyecto, lo cual no superarán tres entregas o modificaciones. "} </p>
              <p className="paragraph" >{"Al finalizar la primera entrega, el usuario no tendrá derecho de ajustes o cambios en las especificaciones del servicio creativo, pues se entenderá trabajo adicional y se cobrará en costos adicionales en la cotización."} </p>
              <p className="subtitle" >{"FACTURA"} </p>
              <p className="paragraph" >{"ComicPlay Studio S.A.S. expedirá factura electrónica, que son pagaderas dentro de los 15 días siguientes a la fecha de su expedición. Si el cliente no realiza el pago dentro del término establecido dará derecho a ComicPlay Studio S.A.S. de suspender o terminar el contrato con el cliente.  "} </p>
              <p className="subtitle" >{"GARANTÍA DE PRODUCTO"} </p>
              <p className="paragraph" >{"De acuerdo al Estatuto del Consumidor, la garantía es una obligación temporal, solidaria a cargo del productor y el proveedor, de responder por el buen estado del producto y la conformidad del mismo con las condiciones de idoneidad, calidad y seguridad legalmente exigibles o las ofrecidas. En razón al anterior y con el fin de satisfacer al usuario con el buen estado y conformidad del producto, ComicPlay Studio S.A.S. garantizará hasta por treinta días 30 días luego de entregar el proyecto. Durante este término de tiempo, al usuario se le garantizará cualquier defecto o problema con respecto al proyecto o servicio creativo. Después de los treinta (30) días siguientes de la entrega, ComicPlay Studio S.A.S. no será responsable de cualquier reclamo sobre el proyecto."} </p>
              <p className="subtitle" >{"POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES"} </p>
              <p className="paragraph" >{"El usuario declara que la información que ha suministrado es verídica y da su consentimiento expreso e irrevocable a ComicPlay Studio S.A.S., o a quien en el futuro haga sus veces como titular del crédito o servicio solicitado, para consultar, en cualquier tiempo y a cualquier base de datos manejada por un operador de información financiera y crediticia, toda la información relevante,  sin limitación alguna. Y  por último, el usuario autoriza a ComicPlay Studio S.A.S. a reportar a cualquier base de datos manejada por un operador de datos, tratados o sin tratar, sobre el cumplimiento  o incumplimiento de sus obligaciones crediticias o atinentes a cualquier relación comercial.  "} </p>
              <p className="subtitle" >{"CONTACTO"} </p>
              <p className="paragraph" >{"El usuario podrá contactarse al correo electrónico comercial@comicplay.studio  en caso de tener dudas, comentarios o aclaración sobre los servicios."} </p>
              <ButtonUp/>      
            </div>
      <div style={{backgroundColor:"#white", marginTop: '0vw' }}>
        <FooterComponent scroll={props.scroll}/>
      </div>
    </div>
  )
}

export default TermsPage