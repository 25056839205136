import React from 'react';
import CarouselComponent from './../CarouselComponent';
import { portfolio } from "../../../constants/portfolio"
import './index.css';

export default function PortfolioComponent(props) {
    return (
        <div ref={props.reference} id="portafolio" className="PortfolioComponent">
            <span className="PortfolioLabel">{"Portfolio"}</span>
            <hr className="PortfolioSeparator" />
            <span className="PortfolioGude">{"Discover some of the projects we've completed by clicking on the image of each cover."}</span>
            <div className="portfolioRow">
                <CarouselComponent images={portfolio[0].images}
                    name={portfolio[0].name}
                    details={portfolio[0].details}
                    year={portfolio[0].year} />
                <CarouselComponent images={portfolio[1].images}
                    name={portfolio[1].name}
                    details={portfolio[1].details}
                    year={portfolio[1].year} />
                <CarouselComponent images={portfolio[2].images}
                    name={portfolio[2].name}
                    details={portfolio[2].details}
                    year={portfolio[2].year} />
            </div>
            <div className="portfolioRow">
                <CarouselComponent images={portfolio[3].images}
                    name={portfolio[3].name}
                    details={portfolio[3].details}
                    year={portfolio[3].year} />
                <CarouselComponent images={portfolio[4].images}
                    name={portfolio[4].name}
                    details={portfolio[4].details}
                    year={portfolio[4].year} />
                <CarouselComponent images={portfolio[5].images}
                    name={portfolio[5].name}
                    details={portfolio[5].details}
                    year={portfolio[5].year} />
            </div>
        </div>
    )
}