import React, { createRef } from 'react';
import "./index.css";
import separatorBottom from "./../../../images/separator-bottom.png";
import separatorTop from "./../../../images/separator-top.png";
import InfoGraphicComponent from "../InfoGraphicComponent";
import branding from "./../../../images/branding.png";
import multimedia from "./../../../images/multimedia.png";
import redes from "./../../../images/redes.png";
import ilustration from "./../../../images/ilustracion.png";
import design from "./../../../images/diseño.png";
import outsourcing from "./../../../images/outsourcing.png";
import lineService from "./../../../images/line.png";
import cross from "./../../../images/cross.png";

const ServicesComponent = () => {
  const refMap = new Map();
  refMap.set('branding', createRef());
  refMap.set('multimedia', createRef());
  refMap.set('ilustracion', createRef());
  refMap.set('redes socioales', createRef());
  refMap.set('diseno web', createRef());
  refMap.set('outsourcing', createRef());
  return(
  <div>
    <div style={{ textAlign: "center" }}>
      <img src={separatorTop} className="separatorTop" alt="separatorTop" />
      <h1 className="serviceTitle"> {"Services"}</h1>
      <hr className="underline"></hr>
    </div>
    <img src={cross} className="cross" alt="cross" />
    <img src={lineService} className="lineService" alt="lineService" />
    
    <div className="ServiceRow">
      <InfoGraphicComponent
      
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="01"
        logoUrl={branding}
        name="BRANDING"
        description="Visual identity, corporate materials, corporate mascot, Packaging."
        fadeIdActive="false"/> 
      <InfoGraphicComponent
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="02"
        logoUrl={multimedia}
        name="MULTIMEDIA"
        description="Motion Graphics, Character Animation, Logo Animation and Corporate Animation."
        fadeIdActive="false"/>
    </div>
    <div className="ServiceRow">
      <InfoGraphicComponent
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="03"
        logoUrl={ilustration}
        name="ILLUSTRATION"
        description="Editorial and Children's Illustration, Marketing Illustration, Visual Development and Concept Art."
        fadeIdActive="false"/>
      <InfoGraphicComponent
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="04"
        logoUrl={redes}
        name="DIGITAL STRATEGY"
        description="Crafting digital and organic ad campaigns, managing communities, creating social media content, and editing videos for Facebook, Instagram, YouTube and TikTok."
        fadeIdActive="false"/>
    </div>
    <div className="ServiceRow">
      <InfoGraphicComponent
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="05"
        logoUrl={design}
        name="WEB DESIGN"
        description="Landing Pages, Online stores, blog design, and app prototypes."
        fadeIdActive="false"/>
      <InfoGraphicComponent
        className="ServicesComp"
        descriptionClassName="DescriptionServices"
        bulletClassName="ServiceRightArrow"
        nameClassName="ServiceTitle"
        iconClassName="ServiceIcon"
        componentFooterClassName="ServiceFooter"
        componentFooter="06"
        logoUrl={outsourcing}
        name="OUTSOURCING"
        description="Offshoring, collaborative outsourcing, outtasking."
        fadeIdActive="false"/>
    </div>


    <img
      src={separatorBottom}
      style={{ width: "100%", marginTop: "4vw", marginBottom: "10vw" }}
      alt="separatorTop"
    />
  </div>
)}

export default ServicesComponent;
