import { eisMilanoImages } from './eisMillano'
import { gatortechImages } from './gatortech'
import { illustrationImages } from './illustration'
import { logofolioImages } from './logofolio'
import { tecnoglassImages } from './tecnoglass'
import { transformateImages } from './transformate'

export const portfolio = [{
    name: "EIS MILANO",
    details: "We created a logo, illustration, and menu design for Eis Milano, a gelateria in the city of Barranquilla specializing in desserts, Italian coffees, breakfast, and their flagship European tradition product, spaghetti gelato.",
    year: "2019",
    images: eisMilanoImages
},
{
    name: "GATOR TECHNOLOGIES OF FLORIDA",
    details: "It is an American company dedicated to calibrating equipment for the gas and oil industry. It stands out especially for its experience with Dynalco instruments over the last 24 years. Our team was responsible for carrying out a rebranding and corporate material renovation that aligns with the new trends and communication needs.",
    year: "2020",
    images: gatortechImages
},
{
    name: "ILLUSTRATION (VARIOUS)",
    details: "We take pride in introducing ourselves as a branding and digital design studio that stands out for creating 100% original illustration, crafted from scratch without intermediaries, to enrich projects with impactful graphics and quality content. Since 2018, we have worked on various cases of character creation, illustrated books, comics, animation, infographics, and much more.",
    year: "2018-2020",
    images: illustrationImages
},
{
    name: "LOGOFOLIO",
    details: "In this section, you can find various logo and branding design projects that cover different types of needs and clients such as small businesses, personal brands, and corporate firms.",
    year: "2016",
    images: logofolioImages
},
{
    name: "CARNIVAL WITH RESPONSIBILITY - TECNOGLASS",
    details: "For the Barranquilla Carnival dates in 2020, an internal marketing campaign was conducted for the company Tecnoglass S.A.S, aimed at raising awareness among its employees about the consequences and dangers of excessive alcohol consumption. We were responsible for providing feedback in copywriting, as well as illustration and design of the deliverable flyers for the campaign.",
    year: "2020",
    images: tecnoglassImages
},
{
    name: "TRANSFORMA-T",
    details: "The Transforma - T platform is an online course platform created in the city of Barranquilla, with the aim of standing out in the market. We created illustrations for over 100 course covers covering various subjects and specialties, from entrepreneurship to personal development. In our portfolio, you'll find a selection of our favorite pieces from the project.",
    year: "2019",
    images: transformateImages
}];

