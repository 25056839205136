import React from 'react';
import './index.css'
import InfoGraphicComponent from './../InfoGraphicComponent';
import firstTeamMember from './../../../images/JAVI.png';
import secondTeamMember from './../../../images/GABY.png';
import thirdTeamMember from './../../../images/BIANCA.png';
import fourth  from './../../../images/anthony.png';
import fifth  from './../../../images/MIGUEL.png';
import seventh  from './../../../images/julieth.png';
import ninth  from './../../../images/CATHERIN.png';

const MeetOurTeamComponent = () => (
    <div className="MeetOurTeamComp">
            <span className="MeetOurTeamLabel">{"Our team"}</span>
            <hr className="Separator"/>
            <div className="OurTeamComp">
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={firstTeamMember} name="JAVIER MARTINEZ CORREA" description="Creative Director and Digital Illustration." fadeInActive="true" />
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={secondTeamMember} name="GABRIELA CARRERA MONTAÑEZ" description="Visual Design Consulting." fadeInActive="true"/>
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={thirdTeamMember} name="BIANCA MARTINEZ RUIZ" description="Marketing Manager." fadeInActive="true"/>
            </div>
            <div className="OurTeamComp">
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={fourth} name="ANTHONY REYES" description="Graphic Designer." fadeInActive="true"/>
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={seventh} name="JULIETH MERCADO" description="Creative Director and Graphic Designer." fadeInActive="true"/>
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={ninth} name="CATHERIN CAMARGO" description="Digital Strategist and UX/UI Leader." fadeInActive="true"/>
            </div>
            <div className="OurTeamComp">
                <InfoGraphicComponent className="TeamMemberComp" descriptionClassName="teamMemberDescription" nameClassName="teamMemberName" iconClassName="TeamMemberIcon" logoUrl={fifth} name="MIGUEL ALTAMIRANDA" description="Software Architect." fadeInActive="true"/>
            </div>
    
    </div>
)

export default MeetOurTeamComponent