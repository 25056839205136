import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import "../TestimonyCarousel/index.css";
import Testimonials from "../Testimony";
import { config } from "react-spring";
import { v4 as uuidv4 } from 'uuid';
import JavierImg from "../../../images/Testimonio_KIKE.png";
import EduardiImg from "../../../images/Testimonio_gator.png";
import VikyImg from "../../../images/Testimonio_VICKY.png";
import SergioImg from "../../../images/SergioChams.png";

export default function TestimonyCarousel() {
  const carouselState = {
    offsetRadius: 2,
    showNavigation: false,
    config: config.slow,
  };
  const [goToSlide, setGoToSlide] = useState(0);
  const goToRight = ()=>{
    setGoToSlide((goToSlide===slide.length-1)?0:goToSlide+1);
  }
  const goToLeft = ()=>{
    setGoToSlide((goToSlide===0)?slide.length-1:goToSlide-1);
  }
  const slide = [{
        key: uuidv4(),
        content: (
          <Testimonials
            currentIndex={goToSlide}
            index={0}
            title="Sergio Chams"
            SergioText="Exceptional service with outstanding results!"
            image ={SergioImg}
            company="Cuadrilátero Boxing"
          />
        ),
      },
        {
          key: uuidv4(),
          content: (
            <Testimonials
              currentIndex={goToSlide}
              index={1}
              description="Comicplay breathed life into my company's social media. What led me to choose to work with them is that they offer something we wanted to include in our social media, which was digital illustrations. The talent of the creatives is admirable."
              image ={JavierImg}
              title="Javier Enrique Martínez"
              company="Úbimo Software"
            />
          ),
        },
        {
          key: uuidv4(),
          content: (
            <Testimonials
              currentIndex={goToSlide}
              index={2}
              title="Eduardo Gomez"
              description="I highly recommend Comicplay.Studio team for their dedication professionalism and their passion they reflected on their work. Above and beyond Bianca and the rest of the Team exceded my expectations with their willingness to complete the project to a level of satisfactory results Gator Technologies of Florida highly recognize this excellent company and will look forward to work with them in upcoming projects. "
              image ={EduardiImg}
              company="Gator Technologies of Florida"
            />
          ),
        },
     
        {
          key: uuidv4(),
          content: (
            <Testimonials
              currentIndex={goToSlide}
              index={3}
              title="Dra. Victoria Alvarez"
              description="They aim to create a product that embodies the identity of the company/person. The creatives succeed in translating the ideas and concepts provided into the design. Bianca conducts a thorough interview, gathering the necessary information to achieve the proposed goal. In my case, it was a logo, and the delivered folder was very comprehensive."
              image ={VikyImg}
              company="Dra. Vicky Pediatría y Nutrición"
            />
          ),
        },
      ].map((slide, index) => {
        return { ...slide, onClick: () => {console.log("going to:"+index);setGoToSlide(index);}};
      });
    

  return (
    <div className="ContainerTestimony">
     <div className="TitleTestimony">Reviews</div> 
     <img alt="right" className="RightArrow" onClick={goToRight}/>
     <img alt="left" className="LeftArrow" onClick={goToLeft}/>
     <div className="SeparatorTestimony"/>
     <img alt="back" className="BlackLine"/>
     <img alt="circle" className="CirculeTest"/>
     <img alt="cross" className="CrossTest"/>
      <div className="CarouselContainerTestimony">

        <Carousel
          slides={slide}
          goToSlide={goToSlide}
          offsetRadius={carouselState.offsetRadius}
          showNavigation={carouselState.showNavigation}
          animationConfig={carouselState.config}
        />
      </div>
    </div>
  );
}
